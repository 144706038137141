.navbar {
  .navbar-brand {
    padding-left: 50px; // Add some padding to the left of the brand
    font-weight: bold;
  }

  .nav-link {
    position: relative;
    padding: 10px 15px;
    font-size: 1.3rem;
    font-weight: bold;

    &:hover {
      text-decoration: none;
      color: #6a7ae4; // Change color on hover
    }

    // Underline the nav item on hover
    &::after {
      content: "";
      position: absolute;
      width: 100%;
      height: 2px;
      background-color: #6a7ae4;
      bottom: 0;
      left: 0;
      transform: scaleX(0);
      transform-origin: bottom right;
      transition: transform 0.25s ease-out;
    }

    &:hover::after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }

  // Highlight the active nav link
  .active-link {
    color: #007bff !important;
    font-weight: bold;

    &::after {
      transform: scaleX(1);
      transform-origin: bottom left;
    }
  }

  // Ensure the navbar is responsive
  @media (max-width: 767px) {
    .navbar-collapse {
      background-color: #f8f9fa;
    }
    .nav-link {
      text-align: center;
      padding: 10px;
    }
  }
}
