.contact-container {
  //   height: calc(100vh - var(--navbar-height));
  height: 100vh;
  padding: 2rem;
  background-color: #d3dfe7;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  .section {
    width: 100%;
    max-width: 800px;
    background-color: #f4f7f9;
    border-radius: 8px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    margin-bottom: 2rem;
    text-align: center;
    transition: transform 0.2s;

    h2 {
      font-size: 2rem;
      color: #333;
      margin-bottom: 1.5rem;
    }

    .contact-list {
      list-style: none;
      padding: 0;
      li {
        font-size: 1.2rem;
        color: #666;
        margin: 1rem 0;
        display: flex;
        align-items: center;
        justify-content: center;

        .icon {
          margin-right: 1rem;
          color: #000000;
          font-size: 1.5rem;
        }

        a {
          text-decoration: none;
          color: #007bff;
          &:hover {
            text-decoration: underline;
          }
        }

        span {
          font-size: 1.2rem;
          color: #666;
        }
      }
    }
  }

  // Responsive styles
  @media (max-width: 768px) {
    .section {
      padding: 1.5rem;
      h2 {
        font-size: 1.8rem;
      }
      .contact-list li {
        font-size: 1.1rem;
        .icon {
          font-size: 1.3rem;
        }
      }
    }
  }
}
