.about-container {
  min-height: 100vh;
  padding: 2rem;
  background-color: #d3dfe7;
  display: flex;
  flex-direction: column;
  align-items: center;

  .section {
    width: 100%;
    max-width: 800px;
    background-color: #f4f7f9;
    border-radius: 8px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    margin-bottom: 2rem;
    padding: 2rem;
    transition: transform 0.2s;

    .profile-image {
      width: 250px;
      height: 250px;
      border-radius: 50%;
      margin: 1rem 0;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    }

    h2 {
      display: flex;
      align-items: center;
      font-size: 2rem;
      color: #333;
      margin-bottom: 1rem;
      svg {
        margin-right: 0.5rem;
      }
    }

    .content {
      h3 {
        font-size: 1.5rem;
        color: #333;
      }

      p,
      li {
        font-size: 1.2rem;
        color: #666;
        line-height: 1.6;
      }

      ul {
        list-style-type: none;
        padding: 0;
        li {
          padding-left: 1rem;
          position: relative;
          &:before {
            content: "•";
            position: absolute;
            left: 0;
            color: #007bff;
          }
        }
      }
    }
  }

  // Responsive styles
  @media (max-width: 768px) {
    .section {
      padding: 1.5rem;
      h2 {
        font-size: 1.8rem;
      }
      .content h3 {
        font-size: 1.4rem;
      }
      .content p,
      .content li {
        font-size: 1.1rem;
      }
    }
  }
}
