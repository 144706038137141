.footer {
  // background-color: #333;
  color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 0.5rem;
  bottom: 0;
  width: 100%;
  font-size: 0.9rem;

  p {
    margin: 0;
  }
}
