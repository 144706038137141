// Container settings
.home-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 2rem;
  background-color: #d3dfe7;

  .profile-section {
    text-align: center;

    .profile-image {
      width: 250px;
      height: 250px;
      border-radius: 50%;
      margin: 10rem 0 5rem;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    }

    h1 {
      font-size: 2.5rem;
      color: #333;
      font-weight: bold;
      margin: 0.5rem 0;
    }

    p {
      font-size: 1.5rem;
      color: #666;
      margin: 0.5rem 0 2rem;
    }

    .scroll-down {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 2rem;
      cursor: pointer;

      p {
        color: #007bff;
        font-size: 1.3rem;
        margin: 0.5rem 0;
      }

      .arrow {
        animation: bounce 2s infinite;
        font-size: 1.5rem;
        color: #007bff;
      }
    }
  }
}

// Links section after scroll
.content-links {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5rem;
  margin: 2rem 1rem;

  .link-card {
    background-color: #f4f7f9;
    padding: 1.5rem;
    border-radius: 8px;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
    text-align: left;
    width: 100%; /* Use full width on smaller screens */
    max-width: 300px; /* Limit the maximum width */
    transition: transform 0.2s;
    display: flex;
    flex-direction: column;
    align-items: center;

    .content-image {
      width: 100%;
      height: auto;
      border-radius: 8px;
      margin-bottom: 1rem;
    }

    &:hover {
      transform: translateY(-5px);
    }

    h3 {
      font-size: 1.5rem;
      color: #333;
      margin-bottom: 1rem;
      font-weight: bold;
    }

    p {
      font-weight: bold;
      font-size: 1.2rem;
      color: #666;
    }
  }
}

// Scroll arrow animation
@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}
