.projects-container {
  padding: 2rem;
  background-color: #d3dfe7;
  text-align: center;
  min-height: 100vh;

  h1 {
    font-size: 2rem;
    color: #333;
    margin-bottom: 2rem;
    font-weight: bold;
  }

  .projects-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 2rem;

    .project-card {
      background-color: #f4f7f9;
      padding: 1.5rem;
      border-radius: 8px;
      box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
      text-align: left;
      width: 600px;
      transition: transform 0.2s;
      display: flex;
      flex-direction: column;
      align-items: center;

      &:hover {
        transform: translateY(-5px);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
      }

      .project-image {
        width: 100%;
        height: auto;
        border-radius: 8px;
        margin-bottom: 1rem;
      }

      h2 {
        color: #333;
        font-size: 1.5rem;
        text-align: center;
      }

      p {
        color: #666;
        font-size: 1.2rem;
        text-align: center;
      }

      .project-link {
        text-decoration: none;
        color: #007bff;
        font-weight: bold;
        margin-top: 1rem;
        display: inline-block;
      }
    }
  }
}

// Responsive design
@media (max-width: 768px) {
  .projects-container {
    padding: 1rem;

    h1 {
      font-size: 1.8rem;
    }

    .projects-list {
      gap: 1.5rem;

      .project-card {
        width: 100%;
        max-width: 400px;
      }
    }
  }
}
