.project-details-container {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
  background-color: #d3dfe7;
  padding: 50px 80px;
  word-wrap: break-word; /* Break long words */
  overflow-wrap: break-word;

  .project-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 1rem;
  }

  .desc-image {
    width: 60%;
    height: auto;
    border-radius: 8px;
    margin: 0 auto 1rem;
    display: block;
  }

  .small-image {
    width: 30%;
    height: auto;
    border-radius: 8px;
    margin: 1rem 1rem;
    display: inline;
  }

  // .video-container {
  //   max-width: 800px;
  //   margin: 20px auto;
  //   text-align: center;
  // }

  .video-container {
    width: 100%;
    height: auto;
    aspect-ratio: 16 / 9; /* Modern approach to keep 16:9 ratio */
    max-width: 100%;
    background-color: #000;
    border-radius: 8px;
    overflow: hidden;
  }

  .video-container iframe,
  .video-container video {
    width: 100%;
    height: 100%;
    object-fit: cover; /* Ensures the video fills the container */
    border-radius: 8px;
    display: block;
  }

  @media (max-width: 768px) {
    .video-container {
      aspect-ratio: 16 / 9; /* Keeps the 16:9 ratio for smaller screens */
      width: 100%;
      height: auto;
    }

    .video-container iframe,
    .video-container video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .section {
    margin: 30px 0;
    padding: 20px;
    background: #f4f7f9;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);

    h2 {
      font-size: 2rem;
      margin-bottom: 15px;
      color: #1c3141;
      font-weight: bold;
    }

    p,
    ul {
      font-size: 1.2rem;
    }

    ul {
      margin-top: 10px;
      list-style: square;
      padding-left: 20px;

      li {
        margin: 8px 0;
      }
    }
  }

  @media (max-width: 768px) {
    .project-details-container {
      padding: 20px 40px; /* Adjust padding for better layout on small screens */

      .section h2 {
        font-size: 1.5rem; /* Smaller headings */
      }

      .section p,
      .section ul {
        font-size: 1rem; /* Smaller paragraph and list font size */
      }

      .features-grid .feature-content h3 {
        font-size: 1rem; /* Adjust feature heading size */
      }

      .features-grid .feature-content p {
        font-size: 0.9rem; /* Adjust feature description size */
      }

      .carousel-container .carousel-title {
        font-size: 1.2rem; /* Smaller carousel title */
      }

      .carousel-container .carousel-description {
        font-size: 1rem; /* Smaller carousel description */
      }

      .carousel-container .carousel-dot {
        width: 8px; /* Smaller dots */
        height: 8px;
      }
    }
  }

  .features-grid {
    display: grid;
    grid-template-columns: 1fr; /* Default: 1 column */
    gap: 2rem;
    justify-content: center;

    .feature {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      text-align: center;
      border: 1px solid #ddd;
      border-radius: 10px;
      padding: 1rem;
      background: #fff;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      transition: transform 0.3s ease, box-shadow 0.3s ease;
      cursor: pointer;

      &:hover {
        transform: translateY(-10px);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); /* Enhanced hover effect */
      }

      .feature-content {
        margin-bottom: 1rem;

        h3 {
          font-size: 1.2rem;
          color: #2c64ad;
          font-weight: bold;
        }

        p {
          font-size: 1rem;
          color: #555;
        }
      }

      .feature-image {
        width: 100%;
        img {
          width: 100%;
          height: auto;
          border-radius: 8px;
          object-fit: cover;
          max-height: 300px; /* Limits image height for consistency */
        }
      }
    }
  }

  @media (min-width: 768px) {
    .features-grid {
      grid-template-columns: 1fr 1fr; /* Two columns for screens >=768px */
      gap: 1.5rem;
    }
  }

  @media (min-width: 1024px) {
    .features-grid {
      grid-template-columns: 1fr 1fr 1fr; /* Three columns for screens >=1024px */
      gap: 1.5rem;
    }
  }

  .overlay {
    z-index: 1000;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;

    .carousel-container {
      position: relative;
      width: 75%;
      background: #fff;
      border-radius: 10px;
      padding: 20px;
      box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5);

      .carousel-title {
        font-size: 1.8rem;
        font-weight: bold;
        color: #333;
        margin-bottom: 10px;
      }

      .carousel-description {
        font-size: 1.2rem;
        margin-bottom: 20px;
        padding-bottom: 20px;
      }

      .carousel-image {
        width: 75%;
        img {
          width: 100%;
          height: auto;
          border-radius: 8px;
          object-fit: cover;
          max-height: 100px; /* Limits image height for consistency */
        }
      }

      .carousel-arrow {
        position: absolute;
        border: none;
        top: 50%;
        transform: translateY(-50%);
        background-color: transparent;
        border-radius: 50%; /* Make the arrow a circle */
        color: #728783;
        font-size: 8rem;
        padding: 10px;
        width: 60px; /* Set a fixed width */
        height: 60px; /* Set a fixed height */
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        z-index: 1000;

        &.prev-arrow {
          left: -10px;
        }

        &.next-arrow {
          right: -10px;
        }

        &:hover {
          color: #a8c3be;
        }
      }

      .carousel-dot {
        display: inline-block;
        width: 10px;
        height: 10px;
        margin: 0 5px;
        background-color: #a8c3be;
        border-radius: 50%;
        cursor: pointer;
        transition: background-color 0.3s ease;

        &.selected {
          background-color: #728783;
        }
      }

      .close-button {
        position: absolute;
        top: 15px;
        right: 15px;
        padding: 0 0.75rem;
        border-radius: 25px;
        font-size: 1.5rem;
        background: #728783;
        border: none;
        color: #ffffff;
        cursor: pointer;
        z-index: 10000;

        &:hover {
          background: #83aba4;
        }
      }
    }

    /* Adjustments for small screens */
    @media (max-width: 768px) {
      .carousel-container {
        width: 90%; /* Reduce the container size on small screens */
        padding: 15px;

        .carousel-arrow {
          width: 40px; /* Smaller arrow size for mobile */
          height: 40px;
          font-size: 2rem; /* Adjust font size */
          top: auto; /* Reset top positioning */
          bottom: 10%; /* Position below the middle */
        }

        .carousel-arrow.prev-arrow {
          left: 10px; /* Add some spacing from the edge */
        }

        .carousel-arrow.next-arrow {
          right: 10px; /* Add some spacing from the edge */
        }

        .close-button {
          top: 10px; /* Adjust top spacing */
          right: 10px; /* Adjust right spacing */
          font-size: 0.85rem; /* Adjust font size for mobile */
          padding: 0.5rem; /* Reduce padding */
          border-radius: 25px;
        }
      }
    }
  }
}
